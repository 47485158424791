/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";

@import "./theme/app-custom.scss";
@import "./theme/app-utils.scss";
@import "./theme/app-theme.scss";

// @import '~ngx-sharebuttons/themes/circles/circles-dark-theme.scss'; // TODO UPDATE ANGULAR

.inner-page-visible {
    display: flex !important;
    z-index: 98 !important;
}

:root {
    ion-img {
      /** 
       * Bug fix for:
       * https://github.com/ionic-team/ionic/issues/18734
       */
      min-height: 1px !important;
    }
  }

  .item-selected-animation {
    transform: scale(0.9);
    transition: transform 300ms;
}

app-grid-item {
  transition: transform 300ms;
}
.search-info-alert .alert-wrapper {
  .alert-button-group {
    justify-content: center;
  }
}

.editor-more-popover .popover-wrapper {
  --width: 180px;
}

.app-text-color {
  color: rgb(66, 66, 66);
}

.app-category-alert .alert-wrapper {
  .alert-head {
    display: none;
  }

  .alert-input-group {
    padding-bottom: 0;
  }

  border-radius: 15px;
}

.app-standard-input-alert .alert-wrapper {
  .alert-head {
    display: none;
  }

  .alert-input-group {
    padding-bottom: 0;
  }

  border-radius: 15px;
}

.app-user-whatsapp-alert .alert-wrapper {
  // .alert-head {
  //   padding-bottom: 0;
  // }

  .alert-message {
    padding-bottom: 0;
  }

  .alert-input-group {
    padding-bottom: 0;
  }

  border-radius: 15px;

  .app-alert-btn-disabled {
    pointer-events: none;
    color: gray;
  }
}


.activity-card-editor-modal {
  --width: 60vmin;
  --height: 70vh;
}

.app-standard-loading .loading-wrapper {
  border-radius: 15px;
  background: rgb(255, 255, 255);
}

.app-standard-alert .alert-wrapper {
  border-radius: 15px;

  .alert-input-group {
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .alert-input {
    text-align: center;
  }

  .alert-button-group {
    justify-content: center;
  }

  .alert-title {
    text-align: center;
  }

  .alert-sub-title {
    text-align: center;
  }

  .alert-message {
    text-align: center;
  }
}

.app-standard-alert-large .alert-wrapper {
  width: 80%;
  max-width: 500px;
  border-radius: 15px;

  .alert-input-group {
    text-align: center;
    padding-top: 0;
    padding-bottom: 0;
  }

  .alert-input {
    text-align: center;
  }

  .alert-button-group {
    justify-content: center;
  }

  .alert-title {
    text-align: center;
  }

  .alert-sub-title {
    text-align: center;
  }

  .alert-message {
    text-align: center;
    max-height: none;
  }
}

.app-alert-msg-selectable .alert-wrapper {
  border-radius: 15px;

  .alert-button-group {
    justify-content: center;
  }

  .alert-title {
    text-align: center;
  }

  .alert-sub-title {
    text-align: center;
  }

  .alert-message {
    text-align: center;
    user-select: all;
  }
}

.alert-button-default {
  color: var(--app-color-blue-primary) !important;
  font-weight: bold !important;
  font-size: large !important;
}

.app-button-primary {
  color: white !important;
  background: var(--app-color-blue-primary) !important;
  border-radius: 0.5em !important;
}

.alert-button-cancel {
  color: var(--app-color-red-primary) !important;
  color: --app-color-red-primary !important;
  font-size: large !important;
}

#version {
  color: #abccff;
  font-size: small;
  padding: 10px;
  border-right-width: 10px;
  text-align: right;
  cursor: pointer;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media only screen and (max-width: 414px) {
  .app-activity-play-start-modal {
    --width: 80%;
    --height: 50%;
  }

  .app-activity-play-start-with-desc-modal {
    --width: 80%;
    --height: 70%;
  }
}

@media only screen and (max-width: 414px) {
  .app-activity-play-end-modal {
    --width: 80%;
    --height: 50%;
  }
}

.app-card-img-editor {
  overflow: hidden;
  margin: 8px !important;
  border-radius: 10px;
  cursor: pointer;
}

.app-terms-link {
  a {
    color: white !important;
    text-decoration: none !important;
    font-weight: bold;
  }
}

@media only screen and (max-width: 1024px) {
  .marketing-modal-large .modal-wrapper {
    height: 90%;
    width: 90%;
  }
}

@media only screen and (min-width: 1024px) {
  .marketing-modal-large .modal-wrapper {
    height: 600px;
    width: 800px;
  }
}

.app-modal-transparent .modal-wrapper {
  background: transparent;
}

.app-modal-share {
  --background: #f5f6f9f2;
  --height: 220px;
  --width: 320px;
  --border-radius: 15px;
}

.app-modal-share-activity {
  --background: #f5f6f9f2;
  --height: 320px;
  --width: 320px;
  --border-radius: 15px;
}

.app-modal-share-invite {
  --background: #f5f6f9f2;
  --height: 250px;
  --width: 320px;
  --border-radius: 15px;
}

.app-modal-board-store-detail .modal-wrapper {
  border-radius: 15px;
}

.app-modal-in-app-purchase-product-detail {
  --background: #f5f6f9f2;
  --height: 550px;
  --width: 320px;
  --border-radius: 15px;
}

.app-aac-toast {
  --background: var(--app-color-aac-primary);
  --color: var(--app-color-aac-primary-contrast);
  --width: 200px;
  text-align: center;
}

.app-activity-toast {
  --background: var(--app-color-activities-primary);
  --color: var(--app-color-activities-primary-contrast);
  --width: 200px;
  text-align: center;
}

.app-toast {
  --background: white;
  --color: #141F34;
  --width: 200px;
  text-align: center;
}

.app-toast-wide {
  --background: white;
  --color: #141F34;
  --width: 300px;
  text-align: center;
}

.app-toast-expand {
  --background: white;
  --color: #141F34;
  text-align: center;
}

ion-toast.app-toast-expand::part(button) {
  font-size: 15px;
  text-transform: none;
  color: white !important;
  background: var(--app-color-blue-primary) !important;
  border-radius: 0.5em !important;
}

.app-popover-lg {
  --width: 300px;
}

.app-popover-md {
  --width: 250px;
}

.app-board-read-only {
  .card-delete-button {
    display: none;
  }

  .card-edit-button {
    display: none;
  }

  .card-more-button {
    display: none;
  }
}

.app-board-locked {
  .card-delete-button {
    display: none;
  }

  .card-edit-button {
    display: none;
  }

  .card-more-button {
    display: none;
  }
}

.app-invite-modal {
  --background: transparent;
  --box-shadow: none;
}

.app-board-fixed {
  .card-delete-button {
    display: none;
  }
}

.app-special-card-start {
  .card-buttons {
    display: none !important;
  }
}

.app-special-card-end {
  .card-buttons {
    display: none !important;
  }
}

.app-badge {
  background: rgb(11, 211, 98);
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.app-box-shadow-none {
  box-shadow: none;
  --box-shadow: none;
}

.app-hide-activities-play-button {
  .play-button-container {
    display: none !important;
  }
}

// .app-category-selected {
//     border-radius: ;
//     box-shadow: rgb(0 0 0 / 20%) 0px 2px 0px -2px, rgb(0 0 0 / 14%) 0px 0px 2px 0px,
//     rgb(0 0 0 / 12%) 0px 0px 5px 0px, white 0px 0.3em 0px 0px, rgb(0 0 0 / 12%) 0px 0.4em 0px 0px, 
//     #57d091 0px 0.6em 0px 0px, 
//     rgb(0 0 0 / 12%) 0px 0.7em 0px 0px !important;
// }

.app-activity-selected {
  opacity: 1 !important;
  box-shadow: 0px 0px 3px 3px #57d091 !important;
  border-radius: 1em;
}

.app-aac-category-selected {
  opacity: 1 !important;
  border-radius: 1em;
  box-shadow: 0px 0px 3px 3px #5F93F3, #5F93F3 0px 0.6em 4px 1px !important;
}

.app-activities-category-selected {
  opacity: 1 !important;
  border-radius: 1em;
  box-shadow: 0px 0px 3px 3px #57d091, #57d091 0px 0.6em 4px 1px !important;
}

.fab-button-in-list {
  height: 30px;
  width: 30px;
}

.safe-area-top {
  padding-top: var(--ion-safe-area-top);
}

.safe-area-bottom {
  padding-bottom: var(--ion-safe-area-bottom);
}

ion-toolbar {
  padding-top: env(safe-area-inset-top, 0px);
}

.app-add-activity-tip-btn-icon {
  width: 25px;
  margin: auto;
}

.app-text-italic {
  font-style: italic;
}

.app-font-size-medium {
  font-size: medium;
}

.app-alert-button-primary {
  font-size: 15px;
  text-transform: none !important;
  color: white !important;
  background: var(--app-color-blue-primary) !important;
  border-radius: 0.5em !important;
}

.app-alert-button-success {
  font-size: 15px;
  text-transform: none !important;
  color: white !important;
  background: var(--ion-color-success) !important;
  border-radius: 0.5em !important;
}

.app-card-border-outline {
  border-radius: 1rem;
  border-style: solid;
  border-width: 0.4rem;

  app-img {
    justify-content: center;
    overflow: hidden;
  }

  img {
    border: none !important;
    margin: auto;
  }
}

// .ion-select-action-sheet .action-sheet-wrapper {
//   .action-sheet-button {
//     width: 85% !important;
//   }

//   .action-sheet-cancel {
//     width: 100% !important;
//   }
// }
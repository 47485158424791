.app-background {
    --background: var(--app-background-color);
    background: var(--app-background-color);
}

.cursor-pointer {
    cursor: pointer;
}

.text-transform-none {
    text-transform: none;
}

.validation-error {
    font-size: small; 
    color: red; 
    margin-bottom: 5px;
  }

.app-d-grid {
    display: grid;
}

.app-d-flex {
    display: flex;
}

.app-d-none {
    display: none !important;
}

.app-justify-content-center {
    justify-content: center;
}

.app-ion-item-padding-end-none {
    --inner-padding-end: 0px;
}

.app-text-wrap {
    line-height: 15pt;
    white-space: pre-wrap;
}

.app-opaque {
    opacity: 1 !important;
}

.app-semi-opaque {
    opacity: 0.4;
}

.app-board-locked {
    opacity: 0.4;
}

.app-scan-card-active {
    opacity: 1 !important;
    box-shadow: 0px 0px 4px 7px rgb(0,130,218) !important;
}

.app-cursor-grabbing {
    cursor: grabbing !important;
}

.app-pointer-event-none {
    pointer-events: none;
}

.app-height-auto {
    height: auto !important;
}

.app-text-small {
    font-size: small;
}

.app-text-transform-none {
    text-transform: none !important;
}

.app-aac-theme {
    --background: var(--app-color-aac-primary); 
    background: var(--app-color-aac-primary); 
    color: var(--app-color-aac-primary-contrast)
}
  
.app-aac-theme-reverse {
    --background: var(--app-color-aac-primary-contrast) !important; 
    color: var(--app-color-aac-primary)
}

.app-aac-theme-background-image {
   background-image: url('/assets/images/backgrounds/expressia-doodles-v1-azul.jpg');
}

.app-aac-theme-background {
    --background: var(--app-color-aac-primary);
    background: var(--app-color-aac-primary);
}

.app-aac-theme-ion-background {
    --background: var(--app-color-aac-primary);
}

.app-aac-theme-color {
    color: var(--app-color-aac-primary)
}

.app-aac-primary-color {
    color: var(--app-color-aac-primary)
}

.app-aac-theme-color-contrast {
    color: var(--app-color-aac-primary-contrast);
    --color: var(--app-color-aac-primary-contrast);
}

.app-activities-theme {
    --background: var(--app-color-activities-primary); 
    background: var(--app-color-activities-primary); 
    color: var(--app-color-activities-primary-contrast)
}
  
.app-activities-theme-reverse {
    --background: var(--app-color-activities-primary-contrast) !important; 
    color: var(--app-color-activities-primary)
}

.app-activities-theme-background-image {
   background-image: url('/assets/images/backgrounds/expressia-doodles-v1-agua.jpg');
}

.app-activities-theme-background {
    --background: var(--app-color-activities-primary);
    background: var(--app-color-activities-primary);
}

.app-activities-theme-ion-background {
    --background: var(--app-color-activities-primary);
}

.app-activities-theme-color {
    color: var(--app-color-activities-primary)
}

.app-activities-theme-color-contrast {
    color: var(--app-color-activities-primary-contrast);
}

.app-activities-toolbar-button {
    --color: var(--app-color-activities-primary-contrast) !important;
}

.app-aac-toolbar {
    --background: var(--app-color-aac-primary); 
    --color: #FFFFFF
}

.app-aac-toolbar-back-button {
    color: var(--app-color-aac-primary-contrast);
    --color: var(--app-color-aac-primary-contrast);
}

.app-aac-toolbar-buttons {
    color: var(--app-color-aac-primary-contrast);
    --color: var(--app-color-aac-primary-contrast);
    color: white;
}

.app-aac-toolbar-button {
    color: var(--app-color-aac-primary-contrast);
    --color: var(--app-color-aac-primary-contrast);
    color: white;
}

.app-aac-toolbar-title {    
    padding: 0;
}

.app-aac-tab-bar {
    --background: var(--app-color-aac-primary); 
    --color: #FFFFFF;
    --border: 0;
}

.app-aac-tab-button {
    --color-selected: white; 
    --color: #97c0ff;
}

.app-aac-tab-bar .tab-selected {
    --color-selected: white; 
    --color: #97c0ff;
    border-width: 0px 0 5px 0px;
    border-style: solid;
}